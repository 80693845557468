import { Controller } from '@hotwired/stimulus';
import { sanitizeInput } from '../js/helper/sanitize.js';

export default class extends Controller {
    static targets = [
        'selectIsoPays',
        'addressInput',
        'zipCode',
        'villeInput',
        'suggestions',
        'suggestionsComplement',
        'etatDiv',
        'etatSelect',
        'etatContainer',
        'etatUpdate',
        'complementInput',
        'lieuDitInput',
        'addAddressBtn',
        'updateAddressBtn',
        'favoriteAddressBtn',
        'removeAddressBtn'
    ];
    timeout = null;
    timeoutComplement = null;

    connect() {
        this.addAddressBtnTargets.forEach(btn => btn.addEventListener('click', e => this.openModal(e)));
        this.updateAddressBtnTargets.forEach(btn => btn.addEventListener('click', e => this.openModal(e)));
        this.favoriteAddressBtnTargets.forEach(btn => btn.addEventListener('click', e => this.favoriteAddress(e)));
        this.removeAddressBtnTargets.forEach(btn => btn.addEventListener('click', e => this.removeAddress(e)));

        document.querySelectorAll('#form_address select').forEach(selectElement => {
            $(selectElement).selectpicker();
        });
        // Permet de gènerer le select "etat" pour les USA et le Canada au chargement de la modal
        if (this.hasSelectIsoPaysTarget){
            this.checkPays();
        }
    }

    // ---- CRUD SUR LES ADRESSES ----
    // Fonction qui permet de supprimer une adresse
    removeAddress(event) {
        const button = event.currentTarget;
        const url = button.getAttribute('data-url');
        const modalTitle = button.getAttribute('data-modalTitle');

        if (!url) {
            return;
        }

        const btnRemoveTrad = document.getElementById('BtnRemoveTrad').getAttribute('data-value');
        const btnCloseTrad = document.getElementById('BtnCloseTrad').getAttribute('data-value');

        bootbox.confirm({
            title: modalTitle,
            message: button.closest('.card').querySelector('.js-address-content').innerHTML,
            buttons: {
                confirm: {
                    label: btnRemoveTrad
                },
                cancel: {
                    label: btnCloseTrad
                }
            },
            size: 'small',
            centerVertical: true,
            callback: (result) => {
                if (result) {
                    fetch(url, {
                        method: 'DELETE'
                    })
                    .then(response => response.json())
                    .then(data => {
                        button.closest('.card').remove();
                    })
                    .catch(error => console.error('Error:', error));
                }
            }
        });
    }

    // Fonction qui permet de mettre une adresse en favori
    favoriteAddress(event) {
        const button = event.currentTarget;
        const isFavorite = button.getAttribute('data-favorite') === '1';
        
        if (isFavorite) {
            return;
        }
        
        const url = button.getAttribute('data-url');
        if (!url) {
            return;
        }

        fetch(url, {
            method: 'PUT',
        })
        .then(response => response.json())
        .then(result => {
            button.setAttribute('data-favorite', '1');
            const svgIcon = button.querySelector('svg');
            svgIcon.classList.add('fa', 'selected-icon');
            svgIcon.classList.remove('far', 'unselected-icon');

            this.favoriteAddressBtnTargets.forEach(el => {
                if (el !== button) {
                    el.setAttribute('data-favorite', '0');
                    const svgIcon = el.querySelector('svg');
                    svgIcon.classList.add('far', 'unselected-icon');
                    svgIcon.classList.remove('fa', 'selected-icon');
                    el.parentNode.querySelector('.js-removeAddressBtn').classList.remove('hidden');
                }
            });

            const addressCard = document.querySelector(`.card-address[data-id="${button.getAttribute('data-id')}"]`);
            const addressList = document.querySelector('.js-addresses-list');
            if (addressCard && addressList) {
                addressList.insertBefore(addressCard, addressList.firstChild);
            }
            button.parentNode.querySelector('.js-removeAddressBtn').classList.add('hidden');
        })
        .catch(error => console.error('Error:', error));
    }

    // Fonction d'appel de la modal d'ajout & de modification d'adresses
    async openModal(event) {
        event.preventDefault();
        const title = event.currentTarget.getAttribute('data-modaltitle');
        const refresh = event.currentTarget.getAttribute('data-refresh') == 'true';

        try {
            let response = await fetch(event.currentTarget.getAttribute('data-url'));
            let result = await response.text();
            this.displayModal(result, title, refresh);
        } catch (error) {
            console.error(error);
        }
    }

    // Fonction de creation des modals , et d'enregistrement des adresses (Dans le callback du bootbox)
    displayModal(content, modalTitle, refresh) {
        let btnValidTrad = document.getElementById('BtnValidTrad').getAttribute('data-value');
        let btnCloseTrad = document.getElementById('BtnCloseTrad').getAttribute('data-value');

        bootbox.confirm({
            show: false,
            message: content,
            title: modalTitle,
            centerVertical: false,
            closeButton: false,
            buttons: {
                confirm: {
                    label: btnValidTrad
                },
                cancel: {
                    label: btnCloseTrad
                }
            },
            locale: document.documentElement.lang,
            callback: (result) => {
                if (result) {
                    let idAddress = document.getElementById('form_address').getAttribute('data-id') || null;
                    let action = idAddress != null ? 'edit-address' : 'add-address';
                    const { countryCode: isoCountry = 'FRA', iso: language = 'FR' } = document.querySelector('#pays').selectedOptions[0].dataset;
                    let isoEtat = document.getElementById('etat') ? document.getElementById('etat').options[document.getElementById('etat').selectedIndex].dataset.valeuretat || "" : "";
                    var raisonSocial = document.querySelector('.modal-body input[name=raisonSociale]') ? sanitizeInput(document.querySelector('.modal-body input[name=raisonSociale]').value.trim()) : '';
                    var lieuDit = document.querySelector('.modal-body input[name=lieu_dit]') ? sanitizeInput(document.querySelector('.modal-body input[name=lieu_dit]').value.trim()) : '';
                    if (this.validateForm() === true) {
                        fetch('/client-adresse/api', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                countryCode: isoCountry,
                                languageCode: language.toLowerCase(),
                                idAddress: idAddress,
                                action: action,
                                libelleAdresse: sanitizeInput(document.querySelector('.modal-body input[name=libelle]').value.trim()),
                                raisonSocialeAdresse: raisonSocial,
                                nomAdresse: sanitizeInput(document.querySelector('.modal-body input[name=nom]').value.trim().toUpperCase()),
                                prenomAdresse: sanitizeInput(document.querySelector('.modal-body input[name=prenom]').value.trim().toUpperCase()),
                                adresse: sanitizeInput(document.querySelector('.modal-body input[name=adresse]').value.trim()),
                                complement: sanitizeInput(document.querySelector('.modal-body input[name=complement]').value.trim()),
                                codePostal: sanitizeInput(document.querySelector('.modal-body input[name=CP]').value.trim()),
                                ville: sanitizeInput(document.querySelector('.modal-body input[name=ville]').value.trim()),
                                lieu_dit: lieuDit,
                                etat: isoEtat,
                                pays: document.querySelector('#pays').selectedOptions[0].value || 65
                            })
                        })
                        .then(response => response.json())
                        .then(result => {
                            let favori = 0;
                            const addressElement = idAddress ? document.querySelector(`.card-address[data-id="${idAddress}"]`) : null;
                            if (addressElement) {
                                favori = addressElement.querySelector('.js-favoriteAddressBtn')?.getAttribute('data-favorite') || 0;
                                addressElement.remove();
                            }
                            const addressesList = document.querySelector('.js-addresses-list');
                            addressesList?.insertAdjacentHTML(favori == '1' ? 'afterbegin' : 'beforeend', result);

                            bootbox.hideAll();
                            if (refresh) {
                                window.location.reload();
                            }
                        })
                        .catch(error => console.error('Error:', error));
                    }
                }
            }
        }).off('shown.bs.modal').modal('show');
    }
    
    // ---- DQE ----
    // Permet de faire l'appel de la recherche DQE
    researchAdress() {
        const selectedCountry = this.selectIsoPaysTarget.options[this.selectIsoPaysTarget.selectedIndex];
        const isoPays = selectedCountry.getAttribute('data-country-code');
        let addressInput = this.addressInputTarget.value.trim();

        // Si l'adresse contient moins de 3 caractères, on ne fait rien
        if(addressInput.length >= 3) {
            this.viewSuggestions();
            addressInput = sanitizeInput(addressInput);
            fetch(`/client-adresse/research`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    isoPays: isoPays,
                    adresse: addressInput
                })
            })
            .then(response => response.json())
            .then(data => {
                this.updateSuggestions(data, isoPays);
            });
        } else {
            clearTimeout(this.timeout);
            this.suggestionsTarget.style.display = 'none';
        }
    }

    // Permet de faire l'appel de la validation RNVP 
    researchComplement(idVoie, number, isoPays) {

        fetch(`/client-adresse-complement`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idVoie,
                number,
                isoPays
            })
        })
        .then(response => response.json())
        .then(data => {

            // Si data n'est pas vide et est un tableau
            if (data && data.length > 0 && Array.isArray(data)) {
            
                this.viewSuggestionsComplement();

                this.complementInputTarget.focus();
                
                this.suggestionsComplementTarget.innerHTML = '';
                data.forEach(suggestion => {
                    const suggestionElement = document.createElement('div');
                    suggestionElement.classList.add('suggestion');
                    suggestionElement.innerHTML = suggestion;
                    suggestionElement.addEventListener('click', () => {
                        this.complementInputTarget.value = suggestion;
                        this.suggestionsComplementTarget.innerHTML = '';
                        this.hideSuggestionsComplement();
                    });
                    this.suggestionsComplementTarget.appendChild(suggestionElement);
                });
            } else {
                this.hideSuggestionsComplement();
            }
            
        });
    }

    // Gère la donnée de la recherche DQE
    updateSuggestions(data, isoPays) {
        const suggestions = Array.isArray(data) ? data : Object.values(data);
        this.suggestionsTarget.innerHTML = '';

        const searchTerm = this.addressInputTarget.value.trim();
        const regex = new RegExp(`(${searchTerm})`, 'gi');

        suggestions.forEach(suggestion => {
            const retourLieuDit = suggestion.LieuDit ? `(${suggestion.LieuDit})` : '';
            if (suggestion.label == ''|| suggestion.label == null) {
                var retourVoie = (suggestion.Numero != "" ? suggestion.Numero + ' ' : '') + (suggestion.TypeVoie != "" ? suggestion.TypeVoie + ' ' : '') + suggestion.Voie;
                if (['AUT', 'BEL', 'BGR', 'CHE', 'CZE', 'DEU', 'ESP', 'HRV', 'ISR', 'ITA', 'NLD', 'POL', 'PRT', 'ROU', 'SWE', 'TUR', 'RUS', 'SVN', 'DNK', 'FIN', 'HUN', 'NOR', 'MEX', 'SVK', 'TWN'].includes(isoPays)) {
                    var retourVoie = (suggestion.TypeVoie != "" ? suggestion.TypeVoie + ' ' : '') + suggestion.Voie + ' ' + suggestion.Numero;
                } 
            } else {
                var retourVoie = suggestion.label.split('|')[0].replace('[', '').replace(']', '');
            }
            const suggestionText = 
                `${retourVoie}<br><span class="text-primary">${suggestion.CodePostal} ${suggestion.Localite} ${retourLieuDit}</span>`;
            const highlightedText = suggestionText.replace(regex, '<span class="saisieUser">$1</span>');

            const suggestionElement = document.createElement('div');
            suggestionElement.classList.add('suggestion');
            suggestionElement.innerHTML = highlightedText;
            suggestionElement.addEventListener('click', () => {
                this.selectSuggestion(suggestion, isoPays);
            });
            this.suggestionsTarget.appendChild(suggestionElement);
        });
    }

    // Gère l'affichage de la recherche DQE
    selectSuggestion(suggestion, isoPays) {
        if (suggestion.Province) {
            this.checkPays(suggestion.Province);
        }
        suggestion.CodePostal = this.verifyZipCode(suggestion.CodePostal, isoPays);
        const selectedCountry = this.selectIsoPaysTarget.options[this.selectIsoPaysTarget.selectedIndex];
        const verifyIsoPays = selectedCountry.getAttribute('data-country-code');
        if (suggestion.label == ''|| suggestion.label == null) {
            if (['AUT', 'BEL', 'BGR', 'CHE', 'CZE', 'DEU', 'ESP', 'HRV', 'ISR', 'ITA', 'NLD', 'POL', 'PRT', 'ROU', 'SWE', 'TUR', 'RUS', 'SVN', 'DNK', 'FIN', 'HUN', 'NOR', 'MEX', 'SVK', 'TWN'].includes(verifyIsoPays)) {
                this.addressInputTarget.value = (suggestion.TypeVoie != "" ? suggestion.TypeVoie + ' ' : '') + suggestion.Voie + ' ' + suggestion.Numero;
            } else {
                this.addressInputTarget.value = (suggestion.Numero != "" ? suggestion.Numero + ' ' : '') + (suggestion.TypeVoie != "" ? suggestion.TypeVoie + ' ' : '') + suggestion.Voie;
            }
        } else {
            this.addressInputTarget.value = suggestion.label.split('|')[0].replace('[', '').replace(']', '');
        }
        this.zipCodeTarget.value = suggestion.CodePostal;
        this.villeInputTarget.value = suggestion.Localite;
        this.suggestionsTarget.innerHTML = '';
        this.complementInputTarget.value = suggestion.Complement ? suggestion.Complement : '';
        this.lieuDitInputTarget.value = suggestion.LieuDit ? suggestion.LieuDit : '';
        // On vérifie la validité du numéro de voie
        if (suggestion.valid_num == 0 || (suggestion.Numero == "" && suggestion.ListeNumero != "" && suggestion.ListeNumero.split(';').length > 1)) {
            this.updateSuggestionsNumber(suggestion, verifyIsoPays);
        } else {
            this.researchComplement(suggestion.IDVoie, suggestion.Numero, verifyIsoPays);
        }
    }

    updateSuggestionsNumber(suggestion, verifyIsoPays) {
        this.suggestionsTarget.innerHTML = '';
        const tabNumero = suggestion.ListeNumero.split(';');
        tabNumero.forEach(numero => {
            const suggestionElement = document.createElement('div');
            suggestionElement.classList.add('suggestion');
            let cleanLabel = '';
            if (suggestion.label == ''|| suggestion.label == null) {
                cleanLabel = suggestion.TypeVoie + ' ' + suggestion.Voie;
            } else {
                cleanLabel = suggestion.label.split('|')[0].replace(/\[.*?\]/g, "");
            }
            suggestionElement.innerHTML = numero + ' ' + cleanLabel.trim();
            this.suggestionsTarget.appendChild(suggestionElement);
            suggestionElement.addEventListener('click', () => {
                this.addressInputTarget.value = numero + ' ' + cleanLabel.trim();
                this.suggestionsTarget.innerHTML = '';
                this.researchComplement(suggestion.IDVoie, numero, verifyIsoPays);
            });
        });
        this.addressInputTarget.focus();
    }

    // Permet de vérifier le code postal en fonction du pays
    verifyZipCode(zipCode, isoPays) {
        // Cas des DOM-TOM
        if (isoPays == 'FRA' && (zipCode.startsWith(97) || zipCode.startsWith(98))) {
            // On est dans le cas des DOM-TOM
            let idPays = 65;
            switch (zipCode.substring(0, 3)) {
                case '971': // Guadeloupe
                    idPays = 172;
                    break;
                case '972': // Martinique
                    idPays = 177;
                    break;
                case '973': // Guyane
                    idPays = 173;
                    break;
                case '974': // Réunion
                    idPays = 171;
                    break;
                case '975': // Saint-Pierre-et-Miquelon
                    idPays = 180;
                    break;
                case '976': // Mayotte
                    idPays = 178;
                    break;
                case '977': // Saint-Barthélemy
                    idPays = 175;
                    break;
                case '978': // Saint-Martin
                    idPays = 179;
                    break;
                case '986': // Wallis-et-Futuna
                    idPays = 202;
                    break;
                case '987': // Polynésie française
                    idPays = 174;
                    break;
                case '988': // Nouvelle-Calédonie
                    idPays = 116;
                    break;
            }
            
            this.resetPays(idPays);
        }
        // Cas des Baleares et des Canaries
        if (isoPays == 'ESP' ) {
            // Canaries
            if ((zipCode >= 35000 && zipCode <= 35660) || (zipCode > 38000 && zipCode <= 38917) || 49581 == zipCode) {
                this.resetPays(192);
            } 
            if (zipCode >= 7000 && zipCode <= 7870) {
                // Baleares
                this.resetPays(212);
            } 
            if ((zipCode >= 51001 && zipCode <= 51080) || (zipCode >= 52000 && zipCode <= 52080)) {
                // Ceuta Melilla
                this.resetPays(193);
            }
        }

        // Cas du Luxembourg
        if (isoPays == 'LUX') {
            // On retire le L- du code postal
            zipCode = zipCode.replace('L-', '');
        };

        // Cas des USA
        if(isoPays == 'USA') {
            // On garde tout ce qu'il y a à gauche du tiret
            zipCode = zipCode.split('-')[0];
        }
        return zipCode;
    }

    // Permet de valider le formulaire d'adresse
    validateForm() {
        let validOk = this.validateFieldsForForm('form_address');
        let _champsErreur = '';
        
        let form = document.querySelector('.modal-body');
        let villeInput = document.querySelector('input[name=ville]');
        let adresseInput = document.querySelector('input[name=adresse]');
        
        // Remove 'has-error' class from all .form-group elements
        form.querySelectorAll('.form-group').forEach(element => {
            element.classList.remove('has-error');
        });
    
    
        if (villeInput && !villeInput.classList.contains('hidden') && !villeInput.value) {
            validOk = false;
            this.toggleError('ville');
            if (_champsErreur === '') {
                _champsErreur = 'ville';
            }
        }

        if (adresseInput && !adresseInput.classList.contains('hidden') && !adresseInput.value) {
            validOk = false;
            this.toggleError('adresse');
            if (_champsErreur === '') {
                _champsErreur = 'adresse';
            }
        }
    
        if (validOk === true) {
            return true;
        } else {
            document.getElementById(_champsErreur).focus();
            return false;
        }
    }
    
    // Permet de valider les champs obligatoires du formulaire
    validateFieldsForForm(id) {
        let form = document.getElementById(id);
        let inputs = form ? [...form.querySelectorAll('input, select, textarea')] : [];
        let fail = 0;

        if (form || inputs.length !== 0) {
            inputs.forEach(element => {
                if (element.required) {
                    if (element.value.trim() === '') {
                        this.toggleError(element);
                        fail++;
                    }
                }
            });
            return fail === 0;
        } else {
            return true;
        }
    }

    // Gestion des erreurs dans le formulaire
    toggleError(_id) {
        const inputGroup = document.getElementById(_id)?.closest('.input-group');
        inputGroup?.classList.add('has-error');
        inputGroup?.classList.remove('has-success');
    }

    // Permet de vider les champs de l'adresse lors d'un changement de pays
    changePays() {
        let clearInput = [this.zipCodeTarget, this.addressInputTarget, this.lieuDitInputTarget, this.complementInputTarget, this.villeInputTarget];
        clearInput.forEach(input => {
            input.value = '';
        });
        this.checkPays();
    }

    // ---- GESTION DU FORMUALAIRE, DES ERREURS ET DES CHAMPS ----
    // Récupère les états pour les USA ou le Canada
    checkPays(currentSelected = null) {
        const idSelectedPays = this.selectIsoPaysTarget.value;
        if (idSelectedPays == 61 || idSelectedPays == 38) {
            if(currentSelected == null) {
                currentSelected = this.etatUpdateTarget.value
            }
            fetch(`/client-adresse/edit/etat`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    idPays: idSelectedPays,
                    currentSelected: currentSelected
                })
            })
            .then(response => response.json())
            .then(data => {
                this.etatDivTarget.innerHTML = data.value;
                $('.selectpicker').selectpicker();
            });
            if (this.etatContainerTarget) {
                this.etatContainerTarget.classList.remove('d-none');
            }
        } else {
            this.etatContainerTarget.classList.add('d-none');
            this.etatDivTarget.innerHTML = '';
        }
    }

    resetPays(idSelectedPays) {
        fetch(`/client-adresse/edit/country`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                currentSelected: idSelectedPays
            })
        })
        .then(response => response.json())
        .then(data => {
            document.getElementById('contain-pays').innerHTML = data.result;
            $('.selectpicker').selectpicker();
        });
    }

    viewSuggestions() {
        clearTimeout(this.timeout);
        this.suggestionsTarget.style.display = 'block';
    }
    hideSuggestions() {
        this.timeout = setTimeout(() => {
            this.suggestionsTarget.style.display = 'none';
        }, 200);
    }

    viewSuggestionsComplement() {
        clearTimeout(this.timeoutComplement);
        this.suggestionsComplementTarget.style.display = 'block';
    }
    hideSuggestionsComplement() {
        this.timeoutComplement = setTimeout(() => {
            this.suggestionsComplementTarget.style.display = 'none';
        }, 200);
    }
}